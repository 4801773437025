import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import ServicesDetails from '@solid-ui-blocks/Faq/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import CompaniesPhoto from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import JoinCompanies from '@solid-ui-blocks/Content/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Footer from '../../blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const PressKit = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Kit de prensa' />
      {/* Modals */}
      <ModalWithTabs title='' content={content['contact']} />
      {/* Blocks */}
      <Header title='' content={content['header-light']} />
      <Container title=''variant='full' sx={styles.heroContainer}>
        <Hero title='' content={content['hero']} />
        <Divider space='4' />
        <Container title=''variant='cards.paper-lg' sx={styles.servicesContainer}>
          <Services title='' content={content['services']} />
        </Container>
      </Container>
      <Divider space='5' />
      <ServicesDetails title='' content={content['services-details']} />
      <Divider space='5' />
      <CompaniesPhoto title='' content={content['companies-photo']} />
      <Divider space='4' />
      <JoinCompanies title='' content={content['companies-join']} />
      <Divider space='4' />
      <Companies title='' content={content['companies']} />
      <Divider space='4' />
      <Contact title='' content={content['cta']} />
      <Divider space='5' />
      <Footer title='' content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageSitePressKitBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/press-kit", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PressKit
